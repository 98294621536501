<template>
    <div class="container">
        <modal-select-positions-in-location ref="modalSelectPositionsInLocation" :workOrderId="workOrderID" :location="selectedLocation" />
        <div class="title-header">
            <div class="row px-3">
                <div class="col-sm-6">
                <h2>
                    <span v-if="workOrder" class="badge bg-primary">{{workOrder.code}}</span>
                    Radne lokacije      
                </h2>
                </div>

                <div v-if="user && (user.role === 'ADMIN')" class="col-sm-6">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="d-grid gap-2">
                                <!-- <button type="button" class="btn-header btn-block btn-primary btn-lg"><i class="fas fa-file-excel fa-lg me-1" /> Export Excel</button> -->
                                <button :disabled="workLocationsStatus == 'SENDING'" v-if="editMode" v-on:click="saveEdit" class="btn btn-success"><i class="fas fa-check fa-lg me-1" /> Sačuvaj izmene</button>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="d-grid gap-2">
                                <button v-if="!editMode" type="button" class="btn-header btn-block btn-primary btn-lg" v-on:click="enableEditMode"><i class="fas fa-edit fa-lg me-1" /> izmena radnih lokacija</button>
                                <button :disabled="workLocationsStatus == 'SENDING'" v-if="editMode" v-on:click="discartEdit" class="btn btn-danger"><i class="fas fa-times fa-lg me-1" /> Poništi izmene</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <work-location-stats v-model="locationsTotal" />
         
        <button class="btn" @click="syncTreeGrid">TEST</button>
        <button class="btn" @click="printGrid">PRINT</button>

    

        <ejs-treegrid
            ref='locationsTree'
            class="locations-table"
            :dataSource='workLocationTree'
            childMapping='children'
            :allowRowDragAndDrop='false'
            :allowReordering='true'
            :allowFiltering='true'
            :allowSorting='true'
            :filterSettings='filterSettings'
            :showColumnMenu='true'
            :allowResizing= 'true'
            :selectionSettings="selection"
            :allowGrouping='true'
            :treeColumnIndex='1'
            :editSettings='editSettings'
            :recordDoubleClick='recordClick'

            :allowPaging='false' 
            :pageSettings='pageSettings'
            :toolbar='toolbar'
            :toolbarClick='toolbarClickHandler'
            :rowDrop="rowDrop" 
            :queryCellInfo='customiseCell'


            :cellEdit='locationChanged'
            :actionComplete='actionCompleteEvent'
            :commandClick='commandClick'
            >
            <e-columns>
                <e-column field='id' headerText='id' isPrimaryKey='true' :allowEditing='false' width='100' :visible='false'></e-column>
                <e-column field='fullIndex' headerText='Šifra' :allowEditing='false' width='100'></e-column>
                <e-column field='name' headerText='Naziv' width='188' textAlign='Left'></e-column>
                <e-column field='positions' headerText='Pozicije' :template="workLocationsPositionsTemplate()" width='100' textAlign='Left'></e-column>
                <!-- <e-column field='positions' headerText='Pozicije' :template="editMode?workLocationsPositionsTemplate():undefined" width='100' textAlign='Left'></e-column> -->

                <e-column headerText='Komande' :commands='commands' :allowEditing='false'></e-column>

            </e-columns>
        </ejs-treegrid>

    </div>
</template>

<script>
require('@/assets/vue-nestable.css')
require('@/assets/tree-view.css')
import {mapState} from 'vuex'
import UtilService from '@/service/UtilService';
import UtilWorkOrder from '@/service/UtilWorkOrder';
// import RequestStatus from '../RequestStatus.vue';
import WorkLocationStats from './WorkLocationStats.vue';
import { Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn } from "@syncfusion/ej2-vue-treegrid";    
import WorkLocationsCMD from '@/components/grid-templates/WorkLocationsCMD.vue';
import ModalSelectPositionsInLocation from '../modals/work-positions/ModalSelectPositionsInLocation.vue';

export default {
    components: { WorkLocationStats, ModalSelectPositionsInLocation },
        
    provide: {
        treegrid: [ Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn ]
    },

    data () {
        return {
            editForm: {insertMethod: 'after', insertLocation:0, group: undefined},
            workLocationsCopy: [],
            workLocationTree: [],
            editMode: false,
            selectedLocation: null,
            locationTypes: [],
            loading_data: true,
            locationsTotal: UtilService.locationsTotal,


            filterSettings: { type: 'Excel' },

            selection: { type: 'Multiple' },
            
            pageSettings: { pageCount: 30},
            toolbarEdit: [
                { text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: '_gridcontrol_add' },
                { text: 'Izbriši', tooltipText: 'Izbriši', prefixIcon: 'e-delete', id: 'deleteLocation' },
                { text: 'Ažuriraj', tooltipText: 'Ažuriraj', prefixIcon: 'e-update', id: '_gridcontrol_update' }, 
                { text: 'Otakži', tooltipText: 'Otakži', prefixIcon: 'e-cancel', id: '_gridcontrol_cancel' }],


            commandsEdit: [
                { type: 'Dodaj', buttonOption: { click:this.addRowBelow, iconCss: ' e-icons e-add', cssClass: 'e-flat' } },
                { type: 'DodajUnutar', buttonOption: { click:this.addRowChild, iconCss: ' e-icons e-add-above', cssClass: 'e-flat' } },
                { type: 'Kopiraj', buttonOption: { click:this.copyRow, iconCss: ' e-icons e-copy', cssClass: 'e-flat' } },
                { type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
                { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
                { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
                { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }],

            editSettings: { allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Row', newRowPosition: 'Child' },
            
            rowDropped: false,


            positionGroupElem: undefined,
            positionTypeElem: undefined,
            positionGroupObj: undefined,
            positionTypeObj: undefined,
            positionGroupData: [],
            positionTypeData: [],
            workLocationsPositionsTemplate() {
                return () => {

                    let self = this;
                    return {
                        template: {
                            parent: self,
                            extends: WorkLocationsCMD,
                            propsData: {
                                column: "POSITIONS",
                                workOrder: this.workOrderID,
                                parent: self,
                            },
                            
                        }

                    };
                }
            },
            
        }
    },

    computed: {
        ...mapState([
            'workOrder',
            'workLocations',
            'positionGroups',
            'workLocationsStatus',
            'workLocationsError',
            'user'
        ]),
        workOrderID() {
            return Number(this.$route.params.woId)
        },

        toolbar() {
            if (this.editMode) return this.toolbarEdit

            return null;
        },

        commands() {
            if (this.editMode) return this.commandsEdit

            return null;
        },

    },
    methods: {

        nestableChanged(a) {
            UtilService.recalculateLocationFullIndex(a);
            this.locationsTotal = UtilService.locationsTotal;
        },

        enableEditMode() {
            this.editMode = true;
            this.$refs.locationsTree.ej2Instances.editSettings.allowEditing = true;
            this.$refs.locationsTree.ej2Instances.allowRowDragAndDrop = true;
            this.$store.dispatch('enableClosePrompt', {message: "Da li želite da izađete. Radne lokacije NISU sačuvane"});
        },

        saveEdit() {
            this.$refs.locationsTree.ej2Instances.showSpinner();

            let data = UtilService.stringifyRecursiveObject(this.workLocationTree);
            this.$store.dispatch('updateWorkLocations', {workOrderId: this.workOrderID, workLocations: JSON.parse(data)}).then(() => {
                this.editMode = false; 
                this.$store.dispatch('disableClosePrompt');

                this.recalculateTreeData();
            });
        },


        
        discartEdit() {
            this.loadWorkLocations();

            this.editMode = false;
            this.$refs.locationsTree.ej2Instances.editSettings.allowEditing = false;
            this.$refs.locationsTree.ej2Instances.allowRowDragAndDrop = false;
            this.$store.dispatch('disableClosePrompt');
        },

        // locationNameAccessor(field, data) {
        //     console.log(field);
        //     return " -- ".repeat(data["level"]) + data[field]
        // },

        customiseCell(args) {
            if (args?.column?.field?.startsWith('name') == true) {
                let margin = Number(args.data.level);

                margin = 1.6 + margin * 0.9

                args.cell.style.paddingLeft = margin + "em";
                if (args.data.level > 0) {
                    args.cell.classList.add('treePaddingBefore');
                }
            }

            if (args.data.deleted) {
                args.cell.classList.add('deleted-row');
            }
        },

        cloneWorkLocationsData() {
            this.workLocationsCopy = JSON.parse(JSON.stringify(this.workLocations));
            UtilService.recalculateLocationFullIndex(this.workLocationsCopy);
            this.locationsTotal = UtilService.locationsTotal;
        },


        getGroupIdByType(typeId) {
            console.log(typeId)
            for(let i = 0 ; i < this.positionGroups.length ; i++) {

                for(let j = 0 ; j < this.positionGroups[i].types.length ; j++) {
                if (this.positionGroups[i].types[j].id === typeId) return this.positionGroups[i];
                }
            }
            return null;

        },

        recursiveFixId(obj) {
            if (!obj.id) {
                obj.id = UtilService.getFreeLocationId();
                obj.fake_id = true;
            }
            obj.children?.forEach(element => {
                this.recursiveFixId(element);
            });
        },


        recursiveFixIdList(objList) {
            objList.forEach(obj => {
                if (!obj.id) {
                    obj.id = UtilService.getFreeLocationId();
                    obj.fake_id = true;
                }
                if (obj.children) this.recursiveFixIdList(obj.children);
            });
        },


        validateFormData() {
            // if (this.editForm.type == null) {
            //     alert("Unesite tip");
            //     return false;
            // }
            return true
        },

        treeViewCollapseBtn(event) {

            const element = event.currentTarget.parentNode.parentNode.nextElementSibling;
        
            if (element.style.display == "block") {
                event.currentTarget.firstChild.innerText ="+"
                element.style.display = "none";
            }
            else
            {
                event.currentTarget.firstChild.innerText ="-"
                element.style.display = "block"  
            }
        },

        async rowDrop() {
            this.rowDropped = true;
        },


        deleteRows(rows) {
            this.setDeleteRows(rows, true)
        },

        revertDeletedRows(rows) {
            this.setDeleteRows(rows, false)
        },

        setDeleteRows(rows, state) {
            this.workLocationTree = this.$refs.locationsTree.ej2Instances.dataModule.hierarchyData

            let idList = rows.map(x => x.id);

            let explore = [...this.workLocationTree];
            let current;
            while(explore.length != 0){
                current = explore[0];
                explore.shift();
                explore.push.apply(explore, current["children"]);
                if (idList.includes(current.id))
                    current.deleted = state;
            }

            this.workLocationTree = [...this.workLocationTree]
            this.$refs.locationsTree.ej2Instances.refresh();

        },

        toolbarClickHandler(args) {
            this.$refs.locationsTree.ej2Instances.editSettings.newRowPosition = "Below"

            console.log(args);
            if (args.item.id === "deleteLocation"){
                let selected = (this.$refs.locationsTree.ej2Instances.getSelectedRecords());
                    if (selected[0].deleted) this.revertDeletedRows(selected)
                    else this.deleteRows(selected)
            }
        },

        async loadWorkLocations() {

            await this.$store.dispatch('loadWorkLocations', {workOrderId: this.workOrderID});
            this.loading_data = false;


            let {data: workLocationTree, total} = UtilWorkOrder.prepareWorkLocationForTree(this.workLocations, this.positionGroups)
            UtilService.removeFieldRecursive(workLocationTree, 'parentNode')
            this.workLocationTree = workLocationTree;
            this.locationsTotal = total
            
        },

        recordClick() {
            console.log(this.$refs.locationsTree);
        },

        locationChanged() {
            alert();
        },

        syncTreeGrid() {
            this.positionGroupObj.dataSource = this.positionGroupData;
            console.log(this.positionGroupObj);
            // this.$refs.locationsTree.ej2Instances.reorderRows(1,3,"above")
            // this.$refs.locationsTree.ej2Instances.selectRow(5);
            // this.$refs.locationsTree.ej2Instances.addRecord();


            // this.$refs.locationsTree.ej2Instances.editSettings.newRowPosition = "Above"
            // this.$refs.locationsTree.ej2Instances.addRecord();

            // this.$refs.locationsTree.ej2Instances.editSettings.newRowPosition = "Above"

            // this.syncDataSource();
        },

        printGrid() {
            console.log(this.$refs.locationsTree);
        },

        actionCompleteEvent(event) {
            if (event.requestType == "save") {
                console.log(event);
                this.recalculateTreeData()
            } else if (event.requestType == "refresh" && this.rowDropped === true) {
                this.rowDropped = false
                this.recalculateTreeData();
            }
        },

        recalculateTreeData() {
            this.workLocationTree = this.$refs.locationsTree.ej2Instances.dataModule.hierarchyData
            this.recursiveFixIdList(this.workLocationTree);
            UtilService.recalculateLocationFullIndex(this.workLocationTree);
            UtilService.removeFieldRecursive(this.workLocationTree, 'parentNode')

            this.syncDataSource();
            this.locationsTotal = UtilService.locationsTotal;

            this.$refs.locationsTree.ej2Instances.hideSpinner();


        },

        syncDataSource() {
            this.workLocationTree = [...this.workLocationTree]
        },

        test() {
            alert("ide")
        },

        addRowBelow(event) {
            let index = this.commandClickGetIndex(event)
            this.treeGridAddRow(index, "Below")
        },

        addRowChild(event) {
            let index = this.commandClickGetIndex(event)
            this.treeGridAddRow(index, "Child")
        },

        commandClickGetIndex(event) {

            let target = event.target
            if (target.tagName != "BUTTON") target = target.parentElement

            let index = target.id.split("_")[2];
            return Number(index)
        },

        treeGridAddRow(index, position="Below") {
            this.$refs.locationsTree.ej2Instances.selectRow(index);
            this.$refs.locationsTree.ej2Instances.editSettings.newRowPosition = position
            this.$refs.locationsTree.ej2Instances.addRecord();

        },

        copyRow(event) {
            let index = this.commandClickGetIndex(event)
            let row = this.$refs.locationsTree.ej2Instances.getRowByIndex(index);


            let id = Number(row.children[1].innerHTML)
            console.log(id);
            
            this.$refs.locationsTree.ej2Instances.collapseRow(row);

            let rawData = this.$refs.locationsTree.ej2Instances.flatData.find(x=>x.id == id);
            let data = UtilService.deepClone(rawData);


            let validKeys = ["name", "children"]
            let explore = [data];
            let current;
            while(explore.length != 0){
                current = explore[0];
                explore.shift();
                explore.push.apply(explore, current.children);
                Object.keys(current).forEach((key) => validKeys.includes(key) || delete current[key]);
            }

            data['name'] = this.getCoppiedRowName(data['name']);

            this.$refs.locationsTree.ej2Instances.editSettings.newRowPosition = "Below"
            this.$refs.locationsTree.ej2Instances.addRecord(data, index);

            this.recalculateTreeData();

        },

        getCoppiedRowName(oldName) {
            let matches = oldName.match(/\d+$/);

            let number = 1
            let numLen = 0
            if (matches) {
                number = Number(matches[0]);
                numLen = matches[0].length;
            }

            number += 1;

            let newName = oldName.slice(0,oldName.length-numLen).trim() + " ";

            newName += number

            return newName;
        },

        commandClick(event) {
            console.log(event);
        },

        
        editLocationPositions(row) {
            this.selectedLocation = row
            this.$refs.modalSelectPositionsInLocation.showModal()

        }

    },

    created() {
        this.loading_data = true;
        this.$store.dispatch('getWorkOrder', {id: this.workOrderID});
        this.loadWorkLocations();

    },

    beforeDestroy() {
        window.onbeforeunload = undefined;

    },


}
</script>

<style>

.container {
    min-height: 74vh;
}

.edit-commands {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    max-height: 50vh;
    /* overflow-x: hidden; */
    overflow-y: auto;
    box-shadow: #00000026 0 6px 20px;
    margin: 0 -0.75rem;
    padding: 0 0.75rem;
    resize: vertical;
    
}

  .bg-selected {
    background: rgb(197, 197, 197);
  }

  .title-header{
    padding: 1em 0;
    background: #299be4;
    color: #fff;
    margin: 10px 0px 0;
  }
  .title-header h2{
    margin: 5px 0 0;
    font-size: 1.8em;
  }
  .title-header .btn-header {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    /* margin-left: 10px; */
  }
  .title-header .btn-header:hover, .title-header .btn-header:focus {
    color: #566787;
    background: #f2f2f2;
  }

  .title-header .btn-header .btn-title {
    margin-top: 20px;
  }

  .location-copy {
      margin-left: 10px;;
  }

  /* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.treePaddingBefore::before {
    content: ' -- ';
    padding: 0.1em;
}

.locations-table table .deleted-row {
    background: #ff4949;
}
.locations-table table tr:hover .deleted-row {
    background: #e93e3e !important;
}

  </style>