<template>
  <div class="workorder-page">
    <Navbar />
    <Breadcrumbs></Breadcrumbs>
    <WorkLocations/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import WorkLocations from '@/components/work-locations/WorkLocations.vue'
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    WorkLocations,
    Footer
  }
}
</script>
