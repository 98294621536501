<template>
    <table class="table table-hover">
        <thead>
            <tr>
                <th v-for="index in value.length" :key="index">{{index}}. Nivo</th>
                <th>Ukupno</th>
            </tr>    
        </thead>
        <tbody>
            <tr>
                <td v-for="(depth,index) in value" :key="index">{{depth}} {{getNoSufix(depth)}}</td>
                <td>{{sumArray()}} {{getNoSufix(sumArray() )}}</td>
            </tr>
        </tbody>
    </table>  
</template>

<script>
export default {
    props: {
        value: Array,
        
    },
    methods: {
        sumArray() {
            let sum = 0;
            this.value.forEach(element => {
                sum += element;
            });
            return sum;
        },

        getNoSufix(number) {
            let string = number.toString();
            const lastNumber = string[string.length-1];
            if (lastNumber == '2' || lastNumber == '3' || lastNumber == '4' ) return "Lokacije";
            return "Lokacija"

        }
    }

}
</script>

<style>


</style>