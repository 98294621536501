<template>
    <div class="modal fade" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <ejs-treegrid
                        ref='positionsInLocation'
                        :dataSource='workPositions'
                        childMapping='children'
                        :selectionSettings="selection"
                        :treeColumnIndex='3'
                        >
                        <e-columns>
                            <e-column type='checkbox' width='100'></e-column>
                            <e-column field='id' headerText='id' isPrimaryKey='true' :allowEditing='false' width='100' :visible='false'></e-column>
                            <e-column field='fullIndex' headerText='Šifra' :allowEditing='false' width='100' :visible='false'></e-column>
                            <e-column field='name' headerText='Naziv' width='188' textAlign='Left'></e-column>
                        </e-columns>
                    </ejs-treegrid>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" @click="confirmModal()">Save changes</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn } from "@syncfusion/ej2-vue-treegrid";    
    import WorkPositionService from '@/service/WorkPositionService'
    import WorkLocationService from '@/service/WorkLocationService'
    import { Modal } from 'bootstrap';

    export default {
        provide: {
            treegrid: [ Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn ]
        },

        props: {
            modalId: String,
            workOrderId: Number,
            location: Object,
        },


        data() {
            return {
                workPositions: [],
                selection: { type: 'Checkbox', checkboxMode: 'Default' },
                modal: null,
            }
        },
        
        methods: {

            async loadWorkPositions() {
                let data = {
                    "workOrder": this.workOrderId,
                }
                let result = await WorkPositionService.getWorkPositions(data)
                this.workPositions = result.data
            },

            getSelectedPositions() {
                return this.$refs.positionsInLocation.getSelectedRecords()
            },

            async saveSelectedPositions() {
                let positions = this.getSelectedPositions();
                let tasksToSave = positions.filter(x => x.position_unit_id)
                .map(position => {
                    return {
                        "work_position_id": position.id,
                        "work_location_id": this.location.id,
                        "normative": -1,
                    }
                })

                await WorkLocationService.postWorkLocationTasks({
                    workLocationId: this.location.id,
                    workOrderId: this.workOrderId,
                    payload: tasksToSave
                })
            
                this.$toasted.show("Azurirana lokacija")

                this.hideModal();

                console.log(tasksToSave);
            },


            confirmModal() {
                this.saveSelectedPositions();
            },

            onModalShown() {
                this.loadWorkPositions();
            },

            setUpListener() {
                
                let myModalEl = this.$el
                this.modal = new Modal(myModalEl, {keyboard: false});

                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.onModalShown();
                })
            },

            showModal() {
                this.modal.show();
            },
            hideModal() {
                this.modal.hide();
            }
        },

        mounted() {
            this.setUpListener();
        },

    }
</script>

<style>

</style>
