<template>
    <div>
        <template v-if="column == 'CMD'">
            <button type="button" class="btn btn-success"><font-awesome-icon icon="check"></font-awesome-icon></button>
            <button type="button" class="btn"><font-awesome-icon icon="trash"></font-awesome-icon></button>
            <button type="button" class="btn"><font-awesome-icon icon="times"></font-awesome-icon></button>
        </template>
        <template v-if="column == 'POSITIONS'">
            <div class="d-flex justify-content-between">
                <span>Selected {{ workOrder }}</span>
                <button type="button" class="btn btn-secondary btn-smm" @click="openPositionsEditorModal"><font-awesome-icon icon="pen"></font-awesome-icon></button>
            </div>
        </template>

    </div>
</template>

<script>
    // import UtilService from '@/service/UtilService.js';

    export default {
        props: ['column', 'workOrder', 'parent'],

        data: function() {
            return {
                data: {},
            }
        },


        methods: {
            openPositionsEditorModal(event) {
                this.parent.editLocationPositions(this.data);
                event.stopPropagation()
            }
        },

        mounted() {
        }
    }
</script>

<style scoped>
    .btn-smm {
        width: 20px;
        height: 20px;
        font-size: 0.7em;
        padding: 0em;
    }

</style>